<template>
  <div class="row">
    <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2">
      <!-- <div class="card">
        <div class="card-body"> -->
      <h1 class="text-center" style="color: white">Thank you</h1>
      <p class="thankyou">
        You should receive your flu voucher via email shortly.
      </p>
      <p class="thankyou">
        In the meantime, enter your postcode below to find your nearest
        pharmacy.
      </p>
      <div class="row mt-4 voucher-home">
        <div class="col-12 col-lg mt-lg-0 mt-1 dual-box-orange text-white p-4">
          <h2 class="mt-1">Find a pharmacy here</h2>
          <div class="mb-3">
            <b-form @submit.stop.prevent="onFindSubmit">
              <div class="d-flex">
                <div class="form-group flex-grow-1 mb-0 mr-1">
                  <b-form-input
                    type="text"
                    class="p-4"
                    placeholder="Enter postcode"
                    v-model="findForm.postcode"
                    :state="validateFindState('postcode')"
                    aria-describedby="input-find-postcode"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    id="input-find-postcode"
                    style="font-size: small !important"
                  >
                    Postcode is required
                  </b-form-invalid-feedback>
                </div>
                <button
                  type="submit"
                  class="btn btn-light btn-voucher btn-lg text-hpcolour-orange"
                >
                  Find
                </button>
              </div>
            </b-form>
          </div>

          <p>Enter your postcode to locate the nearest pharmacies</p>
        </div>
      </div>
      <!-- </div>
      </div> -->
      <!-- <div class="mt-4">
        <a
          class="btn btn-block btn-lg btn-pharmacy btn-flubtn d-flex justify-content-between align-items-center h-flubox"
          @click="navigatePage('Find')"
        >
          <div>
            <img src="/images/pharmacy-marker.png" class="img-fluid" />
          </div>
          <div>Find a pharmacy</div>
          <div>
            <img src="/images/chevron.png" class="" />
          </div>
        </a>
      </div> -->
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
export default {
  name: "Complete",
  mixins: [validationMixin],

  data() {
    return {
      voucherForm: {
        email: null,
      },
      findForm: {
        postcode: null,
      },
      error: false,
      errorMessage: null,
    };
  },
  validations: {
    voucherForm: {
      email: {
        required,
        minLength: minLength(3),
      },
    },
    findForm: {
      postcode: {
        required,
        minLength: minLength(3),
      },
    },
  },
  created() {
    this.$store.dispatch("findForm/clearPostcode");
  },
  methods: {
    navigatePage(page, params) {
      if (params) {
        this.$router.push({ name: page, params: params });
        return;
      }
      this.$router.push({ name: page });
    },
    validateFindState(name) {
      const { $dirty, $error } = this.$v.findForm[name];
      return $dirty ? !$error : null;
    },
    async onFindSubmit() {
      this.$v.findForm.$touch();
      if (this.$v.findForm.$anyError) {
        return;
      }

      await this.$store.dispatch(
        "findForm/setPostcode",
        this.findForm.postcode
      );
      this.navigatePage("Find");
    },
  },
};
</script>